import React, { useState } from 'react'
import Gabbung from '../../assets/hero-animation/gabung.png'
import Logo from '../../assets/logo.png'
import Charity from '../../assets/charity-fund.png'
import Marketing from '../../assets/marketing.png'
import LottieImage from '../lottieimage/LottieImage'
import Logo01 from '../../assets/newlogo/logo01.png'
import Logo02 from '../../assets/newlogo/logo02.png'
import logotext from '../../assets/logo-text.png'

const StarParalax = () => {

    const [alertmessage, setAlertmessage] = useState("");

    const copydata = () => {
        navigator.clipboard.writeText('0xb07905396A419B121213efe1d17cfD0ff20aE597')
    }

    const allertdata = () => {
        setAlertmessage("Copied")
        setTimeout(() => {
            setAlertmessage("")
        }, 700)
    }

    return (
        <div className="starparalax-v2">
            {/* <div class="background">
                <img src={backgroundimg} alt="" />
            </div> */}
            <div className="hero-main-content">
                <div className="hero-sub">
                    <div className="container">
                        <div className="hero-header">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="hero-header-text">
                                        {/* <h1>SAFEPIZZA is now</h1> */}
                                        <img src={logotext} alt="" className="img-responsive" />
                                        {/* <h1>STORE COMING SOON JAN 2022</h1>
                                        <h2>CREATE, BUY, BID, SELL OR TRADE NFTS IN BINANCE SMART CHAIN</h2> */}
                                        <div className="youtube-video">
                                            <div className="main-video">
                                                <iframe width="100%" height="400" src="https://www.youtube.com/embed/udnvTByStEk?controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                            </div>
                                        </div>
                                        <div className="pancake-button">
                                            <a href="https://pancakeswap.finance/swap?outputCurrency=0xb07905396A419B121213efe1d17cfD0ff20aE597" target="_blank">Pancakeswap</a>
                                        </div>
                                        <div className="contract-address-div">
                                            <h4 className="contact-address">0xb07905396A419B121213efe1d17cfD0ff20aE597</h4>
                                            <div className="copybutton">
                                                <button onClick={() => { copydata(); allertdata(); }} ><i class="far fa-copy"></i> Copy Contract Address</button><span>{alertmessage}</span>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default StarParalax
